<template>
  <div class="section">
    <div class="container fluid">
      <div ref="philo" class="philoSection">
        <div class="row">
          <h2>Our Philosophy</h2>
          <div class="separator"></div>
          <h3>
            Innovation combined<br />
            with basis services
          </h3>
          <div class="col-sm-4">
            <div class="card" style="width: 25rem">
              <div class="card-body">
                <img class="card-img-top" src="../assets/img/technology.jpg" />
                <h5 class="card-title">TECHNOLOGY</h5>
                <p class="card-text text">
                  BACS trust in AWERY software solutions powered by Vitaly with
                  offers the most dynamic IT solutions for Air Cargo Aviation
                  able to provide all services with the same efficient software
                  for GSA, charter and Flight assistance services.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card" style="width: 25rem">
              <div class="card-body">
                <img class="card-img-top" src="../assets/img/human.jpg" />
                <h5 class="card-title">HUMAN</h5>
                <p class="card-text text">
                  In our competitive business, we remains still trust in human
                  relationship and combined our IT support with physical meeting
                  with our customers and remains a large phone number available
                  24H a day.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card" style="width: 25rem">
              <div class="card-body">
                <img class="card-img-top" src="../assets/img/services.jpg" />
                <h5 class="card-title">SERVICE</h5>
                <p class="card-text text">
                  We are fully independent and provide additional services more
                  than usual GSA current services : pick up, customs clearance,
                  packing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
export default {
};
</script>
<style scoped>
.section {
  background: linear-gradient(-45deg, #433164, #02061d, #1f6c88);
  background-size: 400% 400%;
  height: 100%;
  position: relative;
}
.philoSection {
  padding-top: 130px;
  padding-bottom: 130px;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
h3 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  margin-bottom: 90px;
  color: #fff;
}
h5 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  color: rgb(223, 223, 223);
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cd1a1ab7;
  padding: 10px;
  border-radius: 5px;
}
.separator {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 9px;
  text-align: center;
  background-color: #cd1a1b;
}
.text {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-align: justify;
  color: rgb(223, 223, 223);
  padding: 20px 0 20px 0;
  border-radius: 5px;
}
.card {
  background-color: #02061da2;
  padding: 20px;
  min-height: 470px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  opacity: 0.6;
  height: 235px;
  object-fit: cover;
}
.card-body {
  flex: 1 1 auto;
  padding: 0rem 0rem;
  position: relative;
}
.bi {
  color: #0c0f20;
  margin-bottom: 10px;
}
</style>