<template>
  <div class="section">
    <div class="container fluid">
      <div ref="contact" class="contactSection">
        <div class="row">
          <div class="col-sm-6">
            <h2>CONTACT US</h2>
            <div class="separator"></div>
            <h3>
              Have any questions?<br />
              Feel free to leave us<br />
              a message
            </h3>
          </div>
          <div class="col-sm-6">
            <form @submit.prevent="sendEmail">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name*"
                  aria-label="Name"
                  name="name"
                  v-model="name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  placeholder="Your email*"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="email"
                  v-model="email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  placeholder="Your message*"
                  aria-label="With textarea"
                  name="message"
                  v-model="message"
                ></textarea>
              </div>
              <button type="submit" class="btn">Send</button>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2">
            <div class="socialIcon">
              <a
                href="https://www.linkedin.com/company/bacs-france/"
                target="_blank"
                ><img class="spaceIcons" src="../assets/img/linkedin_64.png"
              /></a>
              <a href="mailto:bacs@bacs.aero"
                ><img class="spaceIcons" src="../assets/img/email_64.png"
              /></a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <p class="adresses">
                <b>BACS FRANCE</b><br />
                CDG/LFPG Airport<br />
                24 Rue des Voyelles<br />
                93290 Tremblay en France<br />
                CDG AIRPORT<br /><br />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
                +33 1-88-40-63-50
              </p>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
              <p class="adresses">
                <b>BACS OUGADOUGOU</b><br />
                OUA/DFFD Airport<br />
                Rue de l’Aéroport<br />
                Hall de l’aérogare<br />
                Ouagadougou<br />
                BURKINA FASO
              </p>
            </div>
          </div>
          <div class="row">
            <div class="footer">© 2021 BACS | Legal Notice</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
import emailjs from "emailjs-com";

export default {
  name: "ContactUs",
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      if (this.name === "" || this.email === "" || this.message === "") {
        this.$toast.error(`All fields are required!`);
      } else {
        emailjs.sendForm(
          "service_45emusj",
          "template_5t6iubq",
          e.target,
          "user_gTs1fRoccefVJhe6LGB9o",
          {
            name: this.name,
            email: this.email,
            message: this.message,
          }
        );
        this.$toast.success(`Your message was successfully sent`);
      }

      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },
};
</script>
<style scoped>
.section {
  background-image: url(../assets/img/bgDark.png);
  position: relative;
}
.contactSection {
  padding-top: 160px;
  padding-bottom: 10px;
}
a {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px dashed #fff;
}
a:hover {
  color: #cd1a1b;
  border-bottom: 2px dashed #cd1a1b;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}
h3 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  font-weight: 900;
  text-align: left;
  line-height: 70px;
  color: #fff;
}
.separator {
  margin: 40px 0 40px 0;
  width: 80px;
  height: 9px;
  background-color: #cd1a1b;
}
.adresses {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: rgb(113, 112, 112);
  padding-bottom: 100px;
}
.form-control {
  background-color: rgba(255, 255, 255, 0.116);
  border-bottom: 2px solid rgb(168, 168, 168);
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px 0 10px 10px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}
.btn {
  background-color: none;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-align: left;
  color: #cd1a1b;
}
.btn:hover {
  color: #b41919;
}
.socialIcon {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}
.socialIcon img {
  width: 60px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.socialIcon a {
  border-bottom: none;
}
.socialIcon img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.spaceIcons {
  padding-left: 0px;
  padding-right: 20px;
  cursor: pointer;
}
.colLeftSeparator {
  border-left: 1px solid rgba(20, 20, 20, 0.87);
}
.footer {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgb(113, 112, 112);
}
</style>