<template>
  <div class="section">
    <div class="container fluid">
      <div ref="team" class="teamSection">
        <div class="row">
          <div class="col-sm-6 pictureSection">
            <div class="row">
              <div class="col-sm-6">
                <div class="imgContainer">
                  <img class="userImg" src="../assets/img/charles.jpg" />
                </div>
                <p class="name">CHARLES EVRARD</p>
                <p class="subName">MANAGING DIRECTOR</p>
                <div class="socialIcon">
                  <a
                    href="https://www.linkedin.com/in/charles-evrard-3543bb5"
                    target="_blank"
                    ><img class="spaceIcons" src="../assets/img/linkedin.png"
                  /></a>
                  <img class="spaceIcons" src="../assets/img/teams.png" />
                  <img class="spaceIcons" src="../assets/img/wechat.png" />
                  <a href="mailto:charles@bacs.aero"
                    ><img class="spaceIcons" src="../assets/img/email.png"
                  /></a>
                </div>
              </div>
              <div class="col-sm-6">
                <!-- <div class="imgContainer">
                                    <img class="userImg" src="../assets/img/mai.jpg">
                                </div>
                                <p class="name">MAI LE</p>
                                <p class="subName">SALES MANAGER</p>
                                <div class="socialIcon">
                                    <img class="spaceIcons" src="../assets/img/linkedin.png">
                                    <img class="spaceIcons" src="../assets/img/teams.png">
                                    <img class="spaceIcons" src="../assets/img/wechat.png">
                                    <img class="spaceIcons" src="../assets/img/email.png">
                                </div> -->
                <div class="imgContainer">
                  <img class="userImg" src="../assets/img/yacouba.jpg" />
                </div>
                <p class="name">YACOUBA DIAKITE</p>
                <p class="subName">AFRICA MANAGING DIRECTOR</p>
                <div class="socialIcon">
                  <a
                    href="https://www.linkedin.com/in/yacouba-diakite-898146206/"
                    target="_blank"
                    ><img class="spaceIcons" src="../assets/img/linkedin.png"
                  /></a>
                  <a href="mailto:y.diakite@bacs-oua.com"
                    ><img class="spaceIcons" src="../assets/img/email.png"
                  /></a>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="imgContainer">
                  <img class="userImg" src="../assets/img/antoine.jpg" />
                </div>
                <p class="name">ANTOINE BAMBARA</p>
                <p class="subName">AFRICA OPERATIONS DIRECTOR</p>
                <div class="socialIcon">
                  <a
                    href="https://www.linkedin.com/in/bambara-antoine-57062440/"
                    target="_blank"
                    ><img class="spaceIcons" src="../assets/img/linkedin.png"
                  /></a>
                  <a href="mailto:antobambus51@yahoo.com"
                    ><img class="spaceIcons" src="../assets/img/email.png"
                  /></a>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="imgContainer">
                  <img class="userImg" src="../assets/img/mai.jpg" />
                </div>
                <p class="name">MAI LE</p>
                <p class="subName">SALES MANAGER</p>
                <div class="socialIcon">
                  <a
                    href="https://www.linkedin.com/in/mai-le-5118a1133/"
                    target="_blank"
                  >
                    <img class="spaceIcons" src="../assets/img/linkedin.png" />
                  </a>
                  <img class="spaceIcons" src="../assets/img/teams.png" />
                  <img class="spaceIcons" src="../assets/img/wechat.png" />
                  <a href="mailto:mai@bacs.aero">
                    <img class="spaceIcons" src="../assets/img/email.png"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h2>Our Team</h2>
            <div class="separator"></div>
            <h3>
              Skilled, trained and passionate<br />
              by Air Cargo univers
            </h3>
            <p class="text">
              We work to made and build the difference for all cargo and
              customers with very different requirements. We speak English,
              French, Chinese, Vietnamien and hope to add new languages<br />
              and spirit soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
export default {};
</script>
<style scoped>
.section {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: radial-gradient(#444cf7 0.5px, transparent 0.5px),
    radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  box-sizing: content-box;
  width: 100%;
  position: relative;
}
.teamSection {
  padding-top: 160px;
  padding-bottom: 160px;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: right;
  color: #333;
}
h3 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 900;
  text-align: right;
  line-height: 50px;
  color: #333;
}
.separator {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 0;
  margin-left: auto;
  width: 80px;
  height: 9px;
  text-align: right;
  background-color: #cd1a1b;
}
.text {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  color: #333;
}
.pictureSection {
  padding-right: 0px;
}
.imgContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid #cacaca;
  z-index: 1;
  position: relative;
}
.userImg {
  width: 100%;
}
.name {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;
  text-align: center;
  color: #333;
}
.subName {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-top: -15px;
  text-align: center;
  color: rgb(112, 112, 112);
}
.socialIcon {
  margin-bottom: 40px;
}
.socialIcon img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.socialIcon img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.spaceIcons {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
</style>