<template>
    <div>
        <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <router-link class="logo_nav" to="/"><img alt="Logo BACS" src="../assets/logo_navbar.jpg"></router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <span @click="scrollMeTo('home')" class="navLink">HOME</span>
                        </li>
                        <li class="nav-item">
                            <span @click="scrollMeTo('about')" class="navLink">ABOUT US</span>
                        </li>
                        <li class="nav-item">
                            <span @click="scrollMeTo('team')" class="navLink">TEAM</span>
                        </li>
                        <li class="nav-item">
                            <span @click="scrollMeTo('philo')" class="navLink">PHILOSOPHY</span>
                        </li>
                        <li class="nav-item">
                            <a class="navLink" href="https://tracking.bacs.aero/" target="_blank">TRACKING</a>
                        </li>
                        <li class="nav-item">
                            <span @click="scrollMeTo('contact')" class="navLink">CONTACT</span>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
export default {
    name: "Navbar",
    methods:{
        scrollMeTo(refName) {
            this.$emit("scrollMeTo", refName);
        },
    }  
}
</script>
<style>
    .bg-light {
        background-color: #fff !important;
        padding-top: 10px;
    }
    .logo_nav{
        margin-left: 30px;
    }
    #navbarSupportedContent{
        padding-left: 35px;
    }
    .nav-item{
      text-align: right;
    }
    .navLink{
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-weight: 600;
        color: #444;
        cursor: pointer;
        text-decoration: none;
        padding-right: 11px;
        padding-left: 11px;
    }
    .navLink:hover{
        color: #cd1a1b;
    }
    .navbar-collapse .collapse .show{
      background-color: #f1f1f1;
      padding-top: 10px;
      padding-right: 10px;
      margin-top: 10px;
    }
</style>